<template>
  <div id="app">
    <nav v-if="showNavbar">
      <div>
        <h1>Singularity Account Management</h1>

        <div>
          <button @click="navigateToProjectsPage" style="margin-right: 1%;" class="nav-button">Projects</button>
          <button @click="navigateToAllUsers" style="margin-right: 1%;" class="nav-button">Users</button>
          <button @click="navigateToDebitCards" style="margin-right: 1%;" class="nav-button">Cards</button>
          <button @click="navigateToUsers" style="margin-right: 1%;" class="nav-button">MyProfile</button>
          <button @click="logout" class="nav-button">Logout</button>
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showNavbar: false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('userName');
      this.$router.push({ name: 'login' });
    },
    navigateToProjectsPage() {
      if (this.$router.currentRoute.name === 'about') return;
      this.$router.push({ name: 'about' });
    },
    navigateToDebitCards() {
      if (this.$router.currentRoute.name === 'debitcard') return;
      this.$router.push({ name: 'debitcard' });
    },
    navigateToAllUsers() {
      if (this.$router.currentRoute.name === 'users') return;
      this.$router.push({ name: 'users' });
    },
    navigateToUsers() {
      if (this.$router.currentRoute.name == 'profile') return;

      const userId = localStorage.getItem('user_id');
      const userName = localStorage.getItem('userName');
      if (userId) {
        this.$router.push({ name: 'profile', params: { id: userId } });
      } else {
        console.log("User ID not found in localStorage. Redirecting to login.");
        this.$router.push({ name: 'login' });
      }

    }
  },
  watch: {
    $route(to, from) {
      this.showNavbar = to.name !== 'login';

    },
  },
};
</script>



<style>
#app {
  font-family: 'Arial', sans-serif;
  text-align: center;
}

nav {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  margin: 10;
  font-size: 24px;
  color: #343a40;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.nav-button {
  display: inline-block;
  margin-right: 3%;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #0056b3;
}
</style>