<template>
  <div class="container">
    <h1 v-if="(!isLoading && projectDetails)" style="margin-top: 5%;">Project Name: {{ projectDetails.name }}</h1>
    <hr style="border-top: 1px solid #000;">

    <!-- Project Email Section -->
    <div>
      <h1 style="margin-top: 5% !important;">Project Email</h1>
      <div class="section-buttons">
        <button @click="navigateToAddEmail" class="button add">Add</button>
      </div>

      <div v-if="isLoading" class="loading">
        Loading...
      </div>
      <div v-else-if="projectDetails && isDetailsVisible">
        <table class="details-table">
          <tr>
            <th>Email</th>
            <td><input v-model="editableProjectEmail.email" type="email" /></td>
            <th>Password</th>
            <td><input v-model="editableProjectEmail.password" type="text" /></td>
          </tr>
          <tr>
            <th>Phone Details</th>
            <td><input v-model="editableProjectEmail.phone_details" type="text" /></td>
            <th>Recovery Email</th>
            <td><input v-model="editableProjectEmail.recovery_email" type="email" /></td>
          </tr>
          <tr>
            <th>Description</th>
            <td colspan="3"><input v-model="editableProjectEmail.description" type="text" style="width: 100%;" /></td>
          </tr>
        </table>
        <button @click="updateProjectEmail" class="button update">Update</button>
        <button @click="deleteProjectEmail" class="button delete">Delete</button>
      </div>
    </div>

    <hr style="border-top: 1px solid #000;">

    <!-- Provider Account Section -->
    <div>
      <h1 style="margin-top: 5% !important;">Provider Account</h1>
      <div class="section-buttons">
        <button @click="navigateToAddProvider" class="button add">Add</button>
      </div>
      <div v-if="isProviderLoading" class="loading">
        Loading...
      </div>
      <div v-else-if="providerDetails && isProviderDetailsVisible">
        <table class="details-table">
          <tr>
            <th>Provider Name</th>
            <td><input v-model="editableProviderAccount.provider_name" type="text" /></td>

            <th>Account Name or email</th>
            <td><input v-model="editableProviderAccount.account_name" type="text" /></td>
          </tr>
          <tr>
            <th>Password</th>
            <td><input v-model="editableProviderAccount.password_gmail" type="text" /></td>
            <th>Description</th>
            <td><input v-model="editableProviderAccount.description" type="text" /></td>
          </tr>
          <tr>
            <th>Customer Code</th>
            <td><input v-model="editableProviderAccount.customer_code" type="text" /></td>
            <th>Recovery Code</th>
            <td><input v-model="editableProviderAccount.recovery_code" type="text" /></td>
          </tr>

        </table>
        <div class="form-group" v-if="editableProviderAccount.debitcard != null">
          <label for="debitCardId">Card:</label>
          <select id="debitCardId" v-model="editableProviderAccount.debitcard.id">
            <option value="" disabled selected>Select a Card</option>

            <option v-for="card in cards" :key="card.id" :value="card.id">{{ card.card_owner }} - {{ card.card_number }}
            </option>
          </select>

        </div>
        <button @click="updateProviderAccount" class="button update">Update</button>
        <button @click="deleteProviderAccount" class="button delete">Delete</button>
      </div>
    </div>
    <hr style="border-top: 1px solid #000;">

    <!-- Services Section -->
    <div>
      <h1 style="margin-top: 5% !important;">Services</h1>
      <div class="section-buttons">
        <button @click="navigateToAddService" class="button add">Add</button>
      </div>
      <div v-if="isLoading" class="loading">
        Loading...
      </div>
      <div v-else-if="serviceDetails && isServiceDetailsVisible">
        <div v-if="serviceDetails.length > 0">
          <div v-for="(service, index) in serviceDetails" :key="service.id">
            <h3>{{ service.type_name }}</h3>
            <table class="details-table">
              <tr v-for="(attribute, attrIndex) in service.attributes" :key="attrIndex">
                <th>{{ attribute.name }}</th>
                <td>
                  <!-- Editable Value -->
                  <input v-model="attribute.value" type="text" />

                </td>
                <td class="center-btn">
                  <button @click="deleteAttrDetails(index, attrIndex)" class="delete">Delete</button>
                </td>
              </tr>
              <tr>
                <th>Description</th>
                <td>
                  <input v-model="service.description" type="text" />

                </td>

              </tr>
            </table>
            <div class="form-group" v-if="service.debitcard != null">
              <label for="debitCardId">Card:</label>
              <select id="debitCardId" v-model="service.debitcard.id">
                <option value="" disabled selected>Select a Card</option>

                <option v-for="card in cards" :key="card.id" :value="card.id">{{ card.card_owner }} - {{
      card.card_number }}
                </option>
              </select>

            </div>
            <button @click="() => updateServiceDetails(index)" class="button update">Update</button>
            <button @click="() => deleteServiceDetails(index)" class="button delete">Delete</button>
          </div>
        </div>
        <p v-else>No service details available for this project.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      isProviderLoading: true,
      projectDetails: null,
      providerDetails: null,
      serviceDetails: null,
      isDetailsVisible: true,
      isProviderDetailsVisible: true,
      isServiceDetailsVisible: true,
      editableProjectEmail: {},
      editableProviderAccount: {},
      cards: [],
      editableServiceDetails: {},
    };
  },
  mounted() {
    this.fetchProjectDetails();
    this.fetchProviderDetails();
    this.fetchServiceDetails();
    this.fetchDebitCards();

  },
  methods: {
    async fetchProjectDetails() {
      this.isLoading = true;
      const projectId = this.$route.params.id;
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/project/${projectId}/projectemail`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch project details');
        }

        const data = await response.json();
        this.projectDetails = data.data;

        this.editableProjectEmail = this.projectDetails.projectemail ? { ...this.projectDetails.projectemail } : {};
      } catch (error) {
        console.error('Error fetching project details:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProviderDetails() {
      this.isProviderLoading = true;
      const projectId = this.$route.params.id;
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/project/${projectId}/provideraccount`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          console.error('Response not OK:', response.status, response.statusText);
          throw new Error('Failed to fetch provider details');
        }

        const data = await response.json();
        this.providerDetails = data.data;
        this.editableProviderAccount = this.providerDetails.provideraccount ? { ...this.providerDetails.provideraccount } : {};

      } catch (error) {
        console.error('Error fetching provider details:', error);
      } finally {
        this.isProviderLoading = false;
      }
    },
    async fetchServiceDetails() {
      this.isLoading = true;
      const projectId = this.$route.params.id;
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/project/${projectId}/service`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch service details');
        }
        const data = await response.json();

        this.serviceDetails = data.data.service;
        this.editableServiceDetails = this.serviceDetails.services ? { ...this.serviceDetails.services } : null;


      } catch (error) {
        console.error('Error fetching service details:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchDebitCards() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/debitcard/getall`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch debit cards.");
        }

        const data = await response.json();

        if (data.success) {
          this.cards = data.data;
        } else {
          console.error("Failed to fetch debit cards. Server responded with:", data.ar_message || data.en_message);
          alert("Failed to load debit cards: " + (data.ar_message || data.en_message));
        }
      } catch (error) {
        console.error("Failed to fetch debit cards:", error);
        alert("Failed to load debit cards. Please check the console for more details.");
      }
    },
    async deleteProjectEmail() {
      const projectemailId = this.projectDetails.projectemail.id;
      const confirmed = confirm('Are you sure to delete project email?')
      if (!confirmed) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/projectemail/${projectemailId}/delete`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },


        });

        if (!response.ok) {
          throw new Error('Failed to delete project email details');
        }

        alert('Project email delete successfully');
        this.fetchProjectDetails();
      } catch (error) {
        console.error('Error delete project email details:', error);
        alert('Failed to delete project email details. Please check the console for more details.');
      }
    },

    async updateProjectEmail() {
      const projectId = this.$route.params.id;

      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/project/${projectId}/projectemail/update`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.editableProjectEmail),


        });

        if (!response.ok) {
          throw new Error('Failed to update project email details');
        }

        alert('Project email details updated successfully');
        this.fetchProjectDetails();
      } catch (error) {
        console.error('Error updating project email details:', error);
        alert('Failed to update project email details. Please check the console for more details.');
      }
    },

    async deleteProviderAccount() {
      const provideraccountId = this.providerDetails.provideraccount.id;
      console.log(provideraccountId)
      const confirmed = confirm('Are you sure to delete Provider account?')
      if (!confirmed) return;

      this.editableProviderAccount['debit_card_id'] = this.editableProviderAccount.debitcard.id;
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/provider/${provideraccountId}/delete`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.editableProviderAccount),

        });

        if (!response.ok) {
          throw new Error('Failed to delete provider account');
        }

        alert('Providr account delete successfully');
        this.fetchProviderDetails();
      } catch (error) {
        console.error('Error delete providr account:', error);
        alert('Failed to delete providr account: '(data.ar_message || data.en_message));
      }
    },
    async updateProviderAccount() {
      const projectId = this.$route.params.id;
      this.editableProviderAccount['debit_card_id'] = this.editableProviderAccount.debitcard.id;
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/project/${projectId}/provideraccount/update`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.editableProviderAccount),

        });

        if (!response.ok) {
          throw new Error('Failed to update provider account');
        }

        alert('Providr account updated successfully');
        this.fetchProviderDetails();
      } catch (error) {
        console.error('Error updating providr account:', error);
        alert('Failed to update providr account: '(data.ar_message || data.en_message));
      }
    },
    async updateServiceDetails(serviceIndex) {
      const projectId = this.$route.params.id;
      const service = this.serviceDetails[serviceIndex];
      if (service.debitcard) {
        service['debit_card_id'] = service.debitcard.id;
      }
      service.description = service.description == null ? '' : service.description
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/project/${projectId}/service/update`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            service_id: service.id,
            attributes: service.attributes,
            description: service.description,
            debit_card_id: service.debit_card_id,
          }),
        });

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error('Failed to update service details:', errorDetails);
          alert(`Error updating service details: ${errorDetails.message || 'Unknown Error'}`);
          return;
        }
        alert('Service details updated successfully');
        this.fetchServiceDetails();
      } catch (error) {
        console.error('Error updating service details:', error);
        alert('Error updating service details');
      }
    },

    async deleteServiceDetails(serviceIndex) {
      const serviceId = this.serviceDetails[serviceIndex].id;
      console.log(serviceId)
      const confirmed = confirm('Are you sure to delete service ?')
      if (!confirmed) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/service/${serviceId}/delete`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },

        });

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error('Failed to delete service details:', errorDetails);
          alert(`Error delete service details: ${errorDetails.message || 'Unknown Error'}`);
          return;
        }
        alert('Service details delete successfully');
        this.fetchServiceDetails();
      } catch (error) {
        console.error('Error delete service details:', error);
        alert('Error delete service details');
      }
    },

    async deleteAttrDetails(serviceIndex, attrIndex) {
      const serviceId = this.serviceDetails[serviceIndex].id;
      const attrId = this.serviceDetails[serviceIndex].attributes[attrIndex].id;
      console.log(serviceId)
      console.log(attrId)
      const confirmed = confirm('Are you sure to delete attribute ?')
      if (!confirmed) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_BASEAPI_URL}/service/${serviceId}/attribute/${attrId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },

        });

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error('Failed to delete attribute :', errorDetails);
          alert(`Error delete attribute : ${errorDetails.message || 'Unknown Error'}`);
          return;
        }
        alert('Attribute delete successfully');
        this.fetchServiceDetails();
      } catch (error) {
        console.error('Error delete attribute :', error);
        alert('Error delete attribute ');
      }
    },

    navigateToAddService() {
      const projectId = this.$route.params.id;
      if (projectId) {
        this.$router.push({ name: 'addService', params: { id: projectId } });
      } else {
        console.error('Missing "id" parameter in the current route');
      }
    },
    navigateToAddEmail() {
      if (this.$route.params.id) {
        this.$router.push({ name: 'projectemails', params: { id: this.$route.params.id } });

      } else {
        console.error('Missing "id" parameter in the current route');
      }
    },
    navigateToAddProvider() {
      if (this.$route.params.id) {
        this.$router.push({ name: 'projectprovider', params: { id: this.$route.params.id } });

      } else {
        console.error('Missing "id" parameter in the current route');
      }
    }, navigateToAddService() {
      if (this.$route.params.id) {
        this.$router.push({ name: 'services', params: { id: this.$route.params.id } });

      } else {
        console.error('Missing "id" parameter in the current route');
      }
    },
    toggleDetailsVisibility() {
      this.isDetailsVisible = !this.isDetailsVisible;
    },
    toggleProviderDetailsVisibility() {
      this.isProviderDetailsVisible = !this.isProviderDetailsVisible;
    },
    toggleServiceDetailsVisibility() {
      this.isServiceDetailsVisible = !this.isServiceDetailsVisible;
    },
  }
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.buttons {
  margin-bottom: 20px;
}

.button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add {
  background-color: #4CAF50;
  color: white;
}

.toggle {
  background-color: #f2f2f2;
  color: black;
}

.details-table {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #000000;
}

.details-table th,
.details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.details-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.details-table tr:hover {
  background-color: #ddd;
}

.loading {
  text-align: center;
  padding: 20px;
}

.update {
  margin-top: 10px;
  background-color: #2196F3;
  color: white;
}

.delete {
  background-color: red;
  color: white;
}

.center-btn {
  width: 90%;
  text-align: center;
  display: inline-block;
}

.loading {
  text-align: center;
  color: #ff6b6b;
  font-weight: bold;
}
</style>