<template>
  <div class="login-page">
    <div class="login-container">
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="credentials.name" required>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="credentials.password" required>
        </div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <button type="submit">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      credentials: {
        name: '',
        password: '',
      },
      errorMessage: '',
    };
  },
  methods: {
    async login() {
      const apiURL = `${process.env.VUE_APP_BASEAPI_URL}/auth/login`;
      try {
        const response = await axios.post(apiURL, {
          name: this.credentials.name,
          password: this.credentials.password,
        });

        if (response.data.success) {
          const accessToken = response.data.data.access_token;
          const userId = response.data.data.user.id;
          const userName = response.data.data.user.name;
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('user_id', userId.toString());
          localStorage.setItem('userName', userName);
          localStorage.setItem('user_type', response.data.data.user.type);
          this.$router.push({ name: 'about' });
        } else {
          this.errorMessage = response.data.en_message;
        }
      } catch (error) {
        console.error('Error during login:', error.response ? error.response.data : error.message);
        this.errorMessage = error.response ? (error.response.data.en_message || error.response.data.message) : error.message;
      }
    },
  },
  created() {
    const token = localStorage.getItem('access_token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      this.$router.push({ name: 'about' });
    }
  },
};
</script>

<style>
.login-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
}

.form-group input {
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>
