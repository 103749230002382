import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '../views/LoginView.vue';
import ProjectDetailsView from '../views/ProjectDetailsView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/project',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectView.vue')
  },
  {
    path: '/project/:id/projectdetails',
    name: 'projectdetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectDetailsView.vue')
  },
  {
    path: '/project/:id/projectemail',
    name: 'projectemails',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddProjectEmail.vue')
  },
  {
    path: '/project/:id/projectprovider',
    name: 'projectprovider',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddProjectProvider.vue')
  },
  {
    path: '/project/:id/service',
    name: 'services',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddServices.vue')
  },
  {
    path: '/allusers',
    name: 'users',
    component: () => import(/* webpackChunkName: "about" */ '../views/UsersView.vue')
    

  }, 
  {
    path: '/user/:id/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
    

  }, 
  {
    path: '/user/create',
    name: 'storeuser',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddUserView.vue')
    

  }, 
  {
    path: '/debitcard',
    name: 'debitcard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DebitCardView.vue')
    

  },
  {
    path: '/debitcard/create',
    name: 'adddebitcard',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddDebitCardView.vue')
    

  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // Check if the user is authenticated
  const isAuthenticated = localStorage.getItem('access_token');

  // Allow navigation to the login page
  if (to.name === 'login') {
    next();
  } else {
    // Redirect to the login page if not authenticated
    if (!isAuthenticated) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});

export default router;
